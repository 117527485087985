import React, { useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookLoginButton from './FacebookLoginButton';
import classes from './authentication.module.sass';
import loginImg from '../../assets/img/login-img.png';
import { userLogin } from '../../store/actions/user';
import { emailValidation } from '../../helpers';

const Login = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isAuth, ...rest } = props;

  useEffect(() => {
    if (isAuth) {
      history.replace('/account');
    }
  }, [isAuth, history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    rest.userLogin({ email, password });
  };

  const isFormValid = () => (
    emailValidation(email) && password.length > 6
  );

  return (
    <section className={classes.wrap}>
      <Card className={classes.cardWrap}>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item md={6} xs={12}>
              <img src={loginImg} alt="" />
            </Grid>
            <Grid item md={6} xs={12} className={classes.formWrap}>
              <h1>{t('loginPage.mainTitle')}</h1>
              <p>{t('loginPage.subtitle')}</p>
              <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  fullWidth
                  error={!emailValidation(email)}
                  type="email"
                  variant="outlined"
                  label={t('loginPage.form.emailField.placeholder')}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  label={t('loginPage.form.passwordField.placeholder')}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <div className={classes.btnWrap}>
                  <Button
                    fullWidth
                    disabled={!isFormValid()}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large"
                  >
                    {t('loginPage.form.loginButton.text')}
                  </Button>
                </div>
                <div className={classes.linksWrap}>
                  <Link to="/register">{t('loginPage.form.registerButton.text')}</Link>
                  /
                  <Link to="/forgot-password">{t('loginPage.form.forgotButton.text')}</Link>
                </div>
                <div className={classes.separator}>{t('loginPage.form.separator')}</div>
                <FacebookLoginButton btnText={t('loginPage.form.facebookButton.text')} />
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  );
};

Login.propTypes = {
  isAuth: PropsTypes.bool.isRequired,
};

export default connect(store => ({
  isAuth: store.user.isAuth,
}), { userLogin })(Login);

import React from 'react';
import classes from './Password.module.css';
import {Grid, Switch} from '@material-ui/core';
import unlockImg from '../../../assets/img/unlock.svg';
import lockImg from '../../../assets/img/lock.svg';
import {useTranslation} from "react-i18next";

const Password = (props) => {
  const {t} = useTranslation();
  const protectByPassword = props.protectByPassword === 1;
  const newPassword = props.protectByPassword !== 1 && !props.password.length;
  return (
    <div className={classes.password}>
      <div className={classes.w}>
        {t('homePage.passwordField.label')}
      </div>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <img src={unlockImg} alt="" style={{opacity: protectByPassword ? .5 : 1}}/>
        </Grid>
        <Grid item>
          <Switch
            name="protectByPassword"
            color="primary"
            checked={protectByPassword}
            onChange={(e)=> props.changed(e, newPassword)}/>
        </Grid>
        <Grid item>
          <img src={lockImg} alt="" style={{opacity: !protectByPassword ? .5 : 1}}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Password;
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {Facebook} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {connect} from 'react-redux';
import {loginWithFacebook} from '../../store/actions/user'

const FacebookLoginButton = (props) => {
  const responseFacebook = (response) => {
    props.loginWithFacebook(response)
  }
  return (
    <FacebookLogin
      version="7.0"
      appId="3082516631803538"
      fields="email,first_name,last_name"
      callback={responseFacebook}
      render={renderProps => (
        <Button
          fullWidth
          onClick={renderProps.onClick}
          color="primary"
          variant="contained"
          size="large">
          <Facebook style={{marginRight: 5}}/>
          {props.btnText}
        </Button>
      )}
    />
  );
};

export default connect(null, {loginWithFacebook})(FacebookLoginButton);
import React from 'react';
import {Link} from 'react-router-dom';
import classes from './Error404.module.sass'

const Error404 = () => {
  return (
    <div className={classes.p}>
      <div className="container">
        <h1>404</h1>
        <h3>The page is not found</h3>
        <Link to="/">Upload and Send document for signing online! </Link>
      </div>
    </div>
  );
};

export default Error404;
import { CLEAN_STORE, LOAD_MENUS, SEND_FORM, SET_ERROR, SET_LANGUAGE, UPDATE_FORM_FIELD, } from '../actionTypes';
import { API } from '../types';

type UpdateFormField = {
  type: typeof UPDATE_FORM_FIELD,
  payload: {
    name: string,
    value: string,
  }
}

type CleanStore = {
  type: typeof CLEAN_STORE,
}

type SetError = {
  type: typeof SET_ERROR,
  payload: string
};
type SetLanguage = {
  type: typeof SET_LANGUAGE,
  payload: {
    language: string,
  }
};
type SendForm = API & {
  type: typeof SEND_FORM,
  payload: FormData,
};

type LoadMenu = API & {
  type: typeof LOAD_MENUS,
}

export type AppAction = UpdateFormField | CleanStore | SetError | SetLanguage | SendForm | LoadMenu;

export const updateFormField = (name: string, value: string): AppAction => ({
  type: UPDATE_FORM_FIELD,
  payload: { name, value }
});

export const cleanStore = (): AppAction => ({
  type: CLEAN_STORE
});

export const setError = (msg: string): AppAction => ({
  type: SET_ERROR,
  payload: msg
});

export const setLanguage = (language: string): AppAction => ({
  type: SET_LANGUAGE,
  payload: { language }
});

export const sendForm = (payload: FormData): AppAction => ({
  callAPI: '/api/v1/documents',
  method: 'POST',
  progress: true,
  type: SEND_FORM,
  payload
});

export const loadMenu = (): AppAction => ({
  type: LOAD_MENUS,
  callAPI: '/api/v1/content/footer-menus',
  method: 'GET',
});

import React, {useEffect, useState} from 'react';
import classes from './Header.module.sass';
import logo from '../../assets/img/logo.svg';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {cleanStore, setLanguage} from '../../store/actions';
import {userLogout} from '../../store/actions/user';
import {useTranslation} from 'react-i18next';
import {Close, ExitToApp, Menu, Person} from '@material-ui/icons'
import {MenuItem, Select} from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";

const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {t, i18n} = useTranslation();
  useEffect(() => {
    props.setLanguage(i18n.language);
  }, []);
  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    props.setLanguage(e.target.value);
  };
  const selectClasses = {
    root: classes.root,
    icon: classes.icon
  };

  return (
    <header className={classes.Header}>
      <div className={'container ' + (props.isAccountPage ? classes.innerContainer : '')}>
        <div className={classes.row}>
          <Link to="/">
            <img src={logo} alt="" onClick={props.cleanStore}/>
          </Link>
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className={classes.callMenu}>
            {isMenuOpen ?
            <Close/> :
            <Menu/> }
          </button>
          <nav className={isMenuOpen ? classes.open : ''}>
            <Select
              className={classes.select}
              classes={selectClasses}
              value={props.language}
              onChange={changeLanguage}>
              <MenuItem
                classes={{root: classes.option}}
                value="en">
                <ReactCountryFlag className={classes.flag} countryCode="gb" svg/>
                EN
              </MenuItem>
              <MenuItem
                classes={{root: classes.option}}
                value="ru">
                <ReactCountryFlag className={classes.flag} countryCode="ru" svg/>
                RU
              </MenuItem>
            </Select>
            {props.isAuth ?
              <div className={classes.profileWrap}>
                <div className={classes.name}>Welcome {props.firstName} {props.lastName}!</div>
                <Link className={classes.link} to="/account/profile"><Person/> My Profile</Link>
                <button className={classes.authBtn} onClick={props.userLogout}>Logout</button>
              </div> :
              <Link className={classes.authBtn} to="/login">{t('authenticationLink')} <ExitToApp/></Link>}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default connect((store) => ({
  language: store.app.language,
  isAuth: store.user.isAuth,
  firstName: store.user.firstName,
  lastName: store.user.lastName
}), {cleanStore, setLanguage, userLogout})(Header);
import { START, SUCCESS, FAIL, PROCESS, API } from '../actionTypes';
import { Middleware } from 'redux';
import axios, { AxiosResponse } from 'axios';

type ResponseToken = {
  headers: {
    'access-token': string
  }
}
type ResponseDocument = AxiosResponse & {
  data: Blob,
}

const api: Middleware = (store) => (next) => (action) => {
  const { callAPI, type, method, params, responseType, payload, progress } = action;

  if (!callAPI) {
    return next(action);
  }

  next({ ...action, type: type + START });

  const setToken = (response: ResponseToken) => {
    if (response.headers['access-token']) {
      window.localStorage.setItem('access-token', response.headers['access-token']);
    }
  };

  const uploadProgress = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    next({ ...action, type: type + PROCESS, percentCompleted });
  };

  const downloadFile = (response: ResponseDocument) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2)
        fileName = fileNameMatch[1];
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  axios.request({
    method: method,
    url: callAPI,
    data: payload,
    params: {
      language: store.getState().app.language,
      ...params
    },
    responseType: responseType || 'json',
    onUploadProgress: progress && uploadProgress,
    headers: {
      'Access-Token': window.localStorage.getItem('access-token')
    }
  })
    .then((response) => {
      if (action.downloadFile) {
        downloadFile(response);
      }
      setToken(response);
      next({ ...action, type: type + SUCCESS, response: response.data });
    })
    .catch((error) => {
      next({ ...action, type: type + FAIL, error });
      next({ ...action, type: API + FAIL, error: error.response });
    });
}

export default api;

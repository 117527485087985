import {
  FAIL,
  START,
  SUCCESS,
  GET_COMPANY_INFORMATION, UPDATE_COMPANY_INFORMATION, GET_COMPANY_USERS,
  GET_COMPANY_USER_ROLES, ADD_COMPANY_USER, UPDATE_COMPANY_USER, DELETE_COMPANY_USER, GET_DOCUMENT_SENDERS,
} from '../actionTypes';
import { removeFromArray, replaceNullValue, updateObjectInArray, concatActions } from '../../helpers';
import { Company, CompanyAction, CompanyReducer } from '../types/company';

const companyRecord: Company = {
  address: '',
  city: '',
  companyName: '',
  country: 0,
  email: '',
  phoneNumber: '',
  postCode: '',
  vatNumber: '',
};

const initialState: CompanyReducer = {
  ...companyRecord,
  users: [],
  userRoles: [],
  senders: [],
  usersLoaded: false,
  loading: false,
  loaded: false,
};

export default (company: CompanyReducer = initialState, action: CompanyAction): CompanyReducer => {
  switch (action.type) {
    case ADD_COMPANY_USER + START:
    case DELETE_COMPANY_USER + START:
    case UPDATE_COMPANY_USER + START:
    case UPDATE_COMPANY_INFORMATION:
    case UPDATE_COMPANY_INFORMATION + START:
    case GET_COMPANY_USERS + START:
    case GET_COMPANY_INFORMATION + START:
    case GET_COMPANY_USER_ROLES + START:
      return {
        ...company,
        loading: true,
      };
    case concatActions(GET_COMPANY_INFORMATION, SUCCESS):
      if (!action.response.data.company) {
        return {
          ...company,
          ...companyRecord,
          loading: false,
          loaded: true,
        };
      }
      return {
        ...company,
        ...replaceNullValue(action.response.data.company, ''),
        loading: false,
        loaded: true,
      };
    case concatActions(ADD_COMPANY_USER, SUCCESS):
      return {
        ...company,
        users: [
          action.response.data,
          ...company.users,
        ],
        loading: false,
      }
    case concatActions(UPDATE_COMPANY_USER, SUCCESS):
      return {
        ...company,
        users: updateObjectInArray(company.users, {
          index: company.users.findIndex((user) => user.id === action.payload.id),
          item: action.payload,
        }),
        loading: false,
      }
    case concatActions(GET_COMPANY_USERS, SUCCESS):
      return {
        ...company,
        users: action.response.data.users,
        usersLoaded: true,
        loading: false,
      };
    case concatActions(GET_COMPANY_USER_ROLES, SUCCESS):
      return {
        ...company,
        userRoles: Object.keys(action.response.data.roles).map((role) => ({
          value: role,
          label: action.response.data.roles[role],
        })),
        loading: false,
      };
    case concatActions(DELETE_COMPANY_USER, SUCCESS):
      return {
        ...company,
        users: removeFromArray(company.users, {
          index: company.users.findIndex((user) => user.id === action.api?.data),
        }),
        loading: false,
      };
    case concatActions(GET_DOCUMENT_SENDERS, SUCCESS):
      return {
        ...company,
        senders: action.response.data.senders,
        loading: false,
      };
    case ADD_COMPANY_USER + FAIL:
    case UPDATE_COMPANY_USER + FAIL:
    case GET_COMPANY_USERS + FAIL:
    case DELETE_COMPANY_USER + FAIL:
    case GET_COMPANY_USER_ROLES + FAIL:
    case UPDATE_COMPANY_INFORMATION + FAIL:
    case UPDATE_COMPANY_INFORMATION + SUCCESS:
      return {
        ...company,
        loading: false,
      };
    case GET_COMPANY_INFORMATION + FAIL:
      return {
        ...company,
        loading: false,
        loaded: false,
      };
    default:
      return company;
  }
};

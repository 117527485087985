import React from 'react';
import {useTranslation} from "react-i18next";
import classes from "./authentication.module.sass";
import {Button, Card, CardContent, Grid, TextField} from "@material-ui/core";
import forgotImg from "../../assets/img/forgot-password-img.png";
import {Link} from "react-router-dom";

const ForgotPassword = () => {
  const {t} = useTranslation();
  return (
    <section className={classes.wrap}>
      <Card className={classes.cardWrap}>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item  md={6} xs={12}>
              <img src={forgotImg} alt=""/>
            </Grid>
            <Grid item  md={6} xs={12} className={classes.formWrap}>
              <h1>{t('forgotPasswordPage.mainTitle')}</h1>
              <p>{t('forgotPasswordPage.subtitle')}</p>
              <div className={classes.form}> 
                <TextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={t('forgotPasswordPage.form.emailField.placeholder')}
                  name="password"/>
                <div className={classes.btnWrap}>
                  <Button
                    fullWidth
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large">{t('forgotPasswordPage.form.forgotButton.text')}</Button>
                </div>
                <div className={classes.linksWrap}>
                  <Link to="/login">{t('forgotPasswordPage.form.loginButton.text')}</Link>
                  /
                  <Link to="/register">{t('forgotPasswordPage.form.registerButton.text')}</Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  );
};

export default ForgotPassword;
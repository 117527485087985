import { applyMiddleware, createStore, compose } from 'redux';
import reducer from './reducer';
import logger from './middlewares/logger';
import api from './middlewares/api';
import apiNew from './middlewares/api-new';
import socket from './middlewares/socket';
import randomId from './middlewares/randomId';
import thunk from 'redux-thunk';

const enhancer = applyMiddleware(randomId, thunk, api, apiNew, socket, logger);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, {}, composeEnhancers(enhancer));

export default store;

import {
  ADD_DOCUMENT_FIELD,
  ADD_DOCUMENT_FIELD_OPTION,
  ADD_DOCUMENT_PAGE,
  FAIL,
  IS_DOCUMENT_PROTECTED,
  LOAD_DOCUMENT,
  REMOVE_DOCUMENT_FIELD,
  REMOVE_DOCUMENT_FIELD_OPTION,
  SEND_FORM,
  START,
  SUCCESS,
  UPDATE_DOCUMENT_FIELD,
  SEND_DOCUMENT_FIELDS,
  CLEAN_STORE,
  LOAD_DOCUMENT_FILE,
  COMPOSE_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_TEMPLATE,
  LOAD_TEMPLATE_FIELDS,
  SAVE_TEMPLATE,
  CLEAR_DOCUMENT_STATE,
  SWITCH_AUDIT_TRAIL,
} from '../actionTypes';
import { findPageByField, removePropFromObj, updateObjectInArray } from '../../helpers';

const fieldRecord = {
  id: null,
  type: '',
  name: '',
  width: null,
  height: null,
  positionX: null,
  positionY: null,
  required: true,
  readOnly: false,
  value: null,
  letterSpacing: 0,
  signee: 1,
  fillingTime: null,
  direction: 'ltr',
  copyValueTo: null,
  copyValueFrom: null,
  options: {},
  fileForSigning: null,
};
const pageRecord = {
  number: null,
  size: undefined,
  scaleFactor: null,
  fields: {},
};
const defaultState = {
  id: null,
  loading: false,
  loaded: false,
  pages: [],
  token: null,
  isProtected: null,
  uploaded: false,
  signed: false,
  status: null,
  auditTrail: true,
};

const formatPages = (pages) => (
  pages.map((page) => {
    const newPage = { ...page };
    newPage.fields = {};
    page.fields.forEach((field) => {
      newPage.fields[field.id] = {
        ...fieldRecord,
        ...field,
      };
    });
    return newPage;
  })
);

export default (document = defaultState, action) => {
  const {
    type,
    payload,
    randomId,
    response,
  } = action;
  const pageIndex = payload
    ? document.pages.findIndex((page) => page.number === payload.currentPage)
    : null;
  const pageIndexByField = payload
    ? findPageByField(document.pages, payload.id)
    : null;
  switch (type) {
    case SEND_FORM + SUCCESS:
      return {
        ...document,
        loaded: true,
        token: response.data.document.accessToken,
      };
    case ADD_DOCUMENT_PAGE:
      return {
        ...document,
        pages: [
          ...document.pages,
          { ...pageRecord, ...payload },
        ],
      };
    case ADD_DOCUMENT_FIELD:
      return {
        ...document,
        pages: updateObjectInArray(document.pages, {
          index: pageIndex,
          item: {
            ...document.pages[pageIndex],
            fields: {
              ...document.pages[pageIndex].fields,
              [randomId]: {
                ...fieldRecord,
                id: randomId,
                ...payload,
              },
            },
          },
        }),
      };

    case UPDATE_DOCUMENT_FIELD:
      return {
        ...document,
        pages: updateObjectInArray(document.pages, {
          index: pageIndexByField,
          item: {
            ...document.pages[pageIndexByField],
            fields: {
              ...document.pages[pageIndexByField].fields,
              [payload.id]: {
                ...document.pages[pageIndexByField].fields[payload.id],
                [payload.name]: payload.value,
              },
            },
          },
        }),
      };
    case REMOVE_DOCUMENT_FIELD:
      console.log(pageIndexByField);
      return {
        ...document,
        pages: updateObjectInArray(document.pages, {
          index: pageIndexByField,
          item: {
            ...document.pages[pageIndexByField],
            fields: removePropFromObj(document.pages[pageIndexByField].fields, payload.id),
          },
        }),
      };
    case ADD_DOCUMENT_FIELD_OPTION:
      return {
        ...document,
        pages: updateObjectInArray(document.pages, {
          index: pageIndexByField,
          item: {
            ...document.pages[pageIndexByField],
            fields: {
              ...document.pages[pageIndexByField].fields,
              [payload.id]: {
                ...document.pages[pageIndexByField].fields[payload.id],
                options: {
                  ...document.pages[pageIndexByField].fields[payload.id].options,
                  [randomId]: {
                    value: payload.value,
                    id: randomId,
                  },
                },
              },
            },
          },
        }),
      };
    case REMOVE_DOCUMENT_FIELD_OPTION:
      return {
        ...document,
        pages: updateObjectInArray(document.pages, {
          index: pageIndexByField,
          item: {
            ...document.pages[pageIndexByField],
            fields: {
              ...document.pages[pageIndexByField].fields,
              [payload.id]: {
                ...document.pages[pageIndexByField].fields[payload.id],
                options: removePropFromObj(
                  document.pages[pageIndexByField].fields[payload.id].options,
                  payload.optionId,
                ),
              },
            },
          },
        }),
      };
    case SWITCH_AUDIT_TRAIL:
      return {
        ...document,
        auditTrail: payload,
      };
    case IS_DOCUMENT_PROTECTED + START:
      return {
        ...document,
        loading: true,
        token: payload,
      };
    case IS_DOCUMENT_PROTECTED + SUCCESS:
      return {
        ...document,
        loading: false,
        isProtected: response.data.protected,
      };
    case LOAD_DOCUMENT + FAIL:
    case IS_DOCUMENT_PROTECTED + FAIL:
    case LOAD_DOCUMENT_FILE + FAIL:
    case LOAD_DOCUMENT_FILE + SUCCESS:
      return {
        ...document,
        loading: false,
      };
    case SEND_DOCUMENT_FIELDS + START:
    case LOAD_TEMPLATE_FIELDS + START:
    case LOAD_DOCUMENT + START:
    case LOAD_DOCUMENT_FILE + START:
    case COMPOSE_DOCUMENT + START:
      return {
        ...document,
        loading: true,
      };
    case LOAD_DOCUMENT + SUCCESS:
      return {
        ...document,
        id: response.data.document.id,
        token: response.data.document.accessToken,
        pages: formatPages(response.data.document.pages),
        status: response.data.document.status,
        loading: false,
        loaded: true,
      };

    case LOAD_TEMPLATE_FIELDS + SUCCESS:
      return {
        ...document,
        pages: formatPages(response.data.template.pages),
        loading: false,
        loaded: true,
      };
    case SEND_DOCUMENT_FIELDS + SUCCESS:
      return {
        ...document,
        signed: Boolean(payload.isSubmitted),
        loading: false,
        uploaded: true,
      };
    case COMPOSE_DOCUMENT + SUCCESS:
      return {
        ...document,
        loading: false,
        signed: true,
      };
    case DELETE_DOCUMENT + SUCCESS:
      return {
        ...document,
        status: 'deleted',
      };
    case SAVE_TEMPLATE + SUCCESS:
    case UPDATE_TEMPLATE + SUCCESS:
    case CLEAR_DOCUMENT_STATE:
    case CLEAN_STORE:
      return defaultState;
    default:
      return document;
  }
};

import { ClientsAction, ClientsReducer, SessionItem, Sessions } from '../types/clients';
import {
  ADD_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT_SESSIONS,
  GET_CLIENTS, SEND_TEMPLATE_TO_CLIENT,
  START,
  SUCCESS,
  UPDATE_CLIENT
} from '../actionTypes';
import { concatActions, removeFromArray, updateObjectInArray } from '../../helpers';

const initialState: ClientsReducer = {
  statuses: [
    {
      id: 1,
      label: 'Nothing Yet'
    },
    {
      id: 2,
      label: 'Progressing'
    },
    {
      id: 3,
      label: 'All Done'
    },
  ],
  clients: [],
  sessions: {
    clientId: 0,
    sessions: [],
    loading: false,
    loaded: false,
  },
  pagination: null,
  loading: false,
  loaded: false,
};

export default (clients = initialState, action: ClientsAction): ClientsReducer => {
  switch (action.type) {
    case ADD_CLIENT:
    case UPDATE_CLIENT:
    case DELETE_CLIENT:
    case GET_CLIENTS:
      return {
        ...clients,
        loading: true,
      };
    case concatActions(GET_CLIENTS, SUCCESS):
      return {
        ...clients,
        clients: action.response.data.clients,
        loading: false,
        loaded: true,
      };

    case concatActions(ADD_CLIENT, SUCCESS):
      return {
        ...clients,
        clients: [
          ...clients.clients,
          action.response.data.client,
        ],
        loading: false,
      };
    case concatActions(UPDATE_CLIENT, SUCCESS):
      return {
        ...clients,
        clients: updateObjectInArray(clients.clients, {
          index: clients.clients.findIndex((client) => action.api && action.api.data ? client.id === action.api.data.id : false),
          item: action.api && action.api.data
        }),
        loading: false,
      };
    case concatActions(DELETE_CLIENT, SUCCESS):
      return {
        ...clients,
        clients: removeFromArray(clients.clients, {
          index: clients.clients.findIndex((client) => action.payload ? client.id === action.payload.id : false)
        }),
        loading: false,
      };
    case GET_CLIENT_SESSIONS:
      return {
        ...clients,
        sessions: {
          ...clients.sessions,
          clientId: parseInt(action.payload.toString(), 10),
          loading: true,
        }
      };
    case concatActions(GET_CLIENT_SESSIONS, SUCCESS):
      return {
        ...clients,
        sessions: {
          ...clients.sessions,
          loading: false,
          loaded: true,
          sessions: action.response.data.sessions,
        }
      };
    case concatActions(SEND_TEMPLATE_TO_CLIENT, SUCCESS):
      const sessionIndex = clients.sessions.sessions.findIndex((ses) => ses.id === action.response.data.session.id);
      const newItem: SessionItem = {
        id: parseInt(action.payload.id, 10),
        type: 1,
        status: 1,
        title: action.payload.title,
        lastActionDate: '',
      };
      let newSessions: Sessions;
      if (sessionIndex >= 0) {
        newSessions = {
          ...clients.sessions,
          sessions: updateObjectInArray(clients.sessions.sessions, {
            index: sessionIndex,
            item: {
              ...clients.sessions.sessions[sessionIndex],
              items: [
                newItem,
                ...clients.sessions.sessions[sessionIndex].items
              ]
            }
          })
        }
      } else {
        newSessions = {
          ...clients.sessions,
          sessions: [{
            ...action.response.data.session,
            items: [newItem],
          }]
        }
      }
      return {
        ...clients,
        sessions: newSessions,
      };
    default:
      return clients;
  }
};
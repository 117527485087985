import {
  FACEBOOK_LOGIN,
  IS_AUTHENTICATED,
  REGISTER_NEW_USER,
  UPDATE_USER,
  USER_LOGIN,
  USER_LOGOUT,
} from '../actionTypes';

export const loginWithFacebook = (payload) => ({
  type: FACEBOOK_LOGIN,
  callAPI: '/api/v1/users/oauth',
  method: 'POST',
  payload,
  params: {
    source: 'facebook',
  },
});

export const registerNewUser = (payload, isCompany = false) => ({
  type: REGISTER_NEW_USER,
  callAPI: `/api/v1/users/${isCompany ? 'sign-up-with-company' : 'sign-up'}`,
  method: 'POST',
  payload,
});

export const userLogin = (payload) => ({
  type: USER_LOGIN,
  callAPI: '/api/v1/users/sign-in',
  method: 'POST',
  payload,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
  callAPI: '/api/v1/users/sign-out',
  method: 'POST',
});

export const isAuthenticated = () => ({
  type: IS_AUTHENTICATED,
  callAPI: '/api/v1/users/is-authenticated',
});

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  callAPI: '/api/v1/users',
  method: 'PATCH',
  payload,
});

import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from "@material-ui/core";
import classes from "../authentication.module.sass";
import {AccountCircle, Business} from "@material-ui/icons";

const AccountTypeForm = props => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Button
          fullWidth
          className={classes.accountType}
          value="1"
          onClick={props.handleChange}
          name="accountType"
          color="default"
          variant="outlined">
          <div className={classes.accTpWr}>
            <AccountCircle/>
            <h4>Personal</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem, placeat!</p>
          </div>
        </Button>
      </Grid>
      <Grid item sm={6}>
        <Button
          fullWidth
          className={classes.accountType}
          value="2"
          onClick={props.handleChange}
          name="accountType"
          color="default"
          variant="outlined">
          <div className={classes.accTpWr}>
            <Business/>
            <h4>Company</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, aspernatur.</p>
          </div>
        </Button>
      </Grid>
    </Grid>
  );
};

AccountTypeForm.propTypes = {
  handleChange: PropTypes.func.isRequired
};

export default AccountTypeForm;
import { GET_COUNTRIES, LOAD_RULES } from '../actionTypes';

export const getCountries = () => ({
  callAPI: '/api/v1/content/countries',
  type: GET_COUNTRIES,
});

export const loadRules = () => ({
  callAPI: '/api/v1/content/rules',
  type: LOAD_RULES,
});

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, IconButton, InputAdornment, TextField} from "@material-ui/core";
import {emailValidation} from "../../../helpers";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useTranslation} from "react-i18next";

const PersonalForm = props => {
  const {t} = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const {values, handleChange} = props;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label={t('registerPage.form.firstNameField.placeholder')}
            name="firstName"
            value={values.firstName}
            onChange={handleChange}/>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label={t('registerPage.form.lastNameField.placeholder')}
            name="lastName"
            value={values.lastName}
            onChange={handleChange}/>
        </Grid>
      </Grid>
      <TextField
        fullWidth
        error={!emailValidation(values.email)}
        type="text"
        variant="outlined"
        label={t('registerPage.form.emailField.placeholder')}
        value={values.email}
        name="email"
        onChange={handleChange}/>
      <TextField
        fullWidth
        variant="outlined"
        label={t('registerPage.form.passwordField.placeholder')}
        type={showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange}
        name="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}/>
    </div>
  );
};

PersonalForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default PersonalForm;
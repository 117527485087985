import {
  ADD_TEMPLATE_FILE,
  DELETE_TEMPLATE,
  FAIL,
  GET_TEMPLATES, LOAD_TEMPLATE_FIELDS,
  LOAD_TEMPLATE_FILE, SAVE_TEMPLATE, SET_TEMPLATE_RECIPIENT, SET_TEMPLATES_FOR_SENDING,
  START,
  SUCCESS,
  UPDATE_TEMPLATE,
} from '../actionTypes';
import { updateObjectInArray } from '../../helpers';

const templateRecord = {
  id: null,
  title: null,
  status: null,
  createdAt: false,
  updatedAt: false,
};

const defaultState = {
  templates: [],
  pagination: null,
  templatesForSending: null,
  templateRecipientEmail: '',
  templateRecipientPhone: '',
  templateRecipientName: '',
  file: null,
  template: { ...templateRecord },
  loading: false,
  loaded: false,
};

const getTemplateIndexById = (templates, id) => {
  let index = 0;
  templates.forEach((template, i) => {
    if (template && parseInt(template.id, 10) === id) {
      index = i;
    }
  });
  return index;
};

export default (templates = defaultState, action) => {
  const { type, payload, response } = action;
  const tmp = [...templates.templates];
  switch (type) {
    case GET_TEMPLATES + START:
      return {
        ...templates,
        loading: true,
        loaded: false,
      };
    case GET_TEMPLATES + SUCCESS:
      tmp.splice(action.params.offset, action.params.limit, ...response.data.templates);
      return {
        ...templates,
        templates: tmp,
        pagination: response.data.pagination,
        loading: true,
        loaded: false,
      };
    case GET_TEMPLATES + FAIL:
      return {
        ...templates,
        loading: false,
        loaded: false,
      };
    case DELETE_TEMPLATE + SUCCESS:
      return {
        ...templates,
        templates: templates.templates.filter((template) => template.id !== payload),
        pagination: {
          ...templates.pagination,
          total: templates.pagination.total - 1,
        },
      };
    case UPDATE_TEMPLATE + SUCCESS:
      return {
        ...templates,
        file: null,
        templates: updateObjectInArray(templates.templates, {
          index: getTemplateIndexById(templates.templates, response.data.template.id),
          item: response.data.template,
        })
      };
    case LOAD_TEMPLATE_FILE + SUCCESS:
      return {
        ...templates,
        file: new Blob([response], { type: 'application/pdf' }),
      };
    case ADD_TEMPLATE_FILE:
      return {
        ...templates,
        file: payload,
      };

    case SAVE_TEMPLATE + SUCCESS:
      return {
        ...templates,
        templates: [response.data.template, ...templates.templates],
      };
    case LOAD_TEMPLATE_FIELDS + SUCCESS:
      return {
        ...templates,
        template: response.data.template,
      };
    case SET_TEMPLATES_FOR_SENDING:
      return {
        ...templates,
        templatesForSending: payload,
      };
    case SET_TEMPLATE_RECIPIENT:
      return {
        ...templates,
        [payload.name]: payload.value,
      };
    default:
      return templates;
  }
};

import { createMuiTheme } from '@material-ui/core/styles';

export const palette = {
  type: 'light',
  primary: {
    main: '#292C4A',
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    light: '#ff7171',
    main: '#FF6565',
    // dark: will be calculated from palette.secondary.main,
  },
  success: {
    main: '#51CC51'
  },
  warning: {
    main: '#FFBA65'
  },
  text: {
    primary: '#0A0A0A',
    white: '#ffffff'
  },
  background: {
    default: '#FDFDFD'
  }
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Open Sans',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h6: {
      fontWeight: 700,
      fontSize: '1.4rem'
    }
  },
  palette: palette,
  overrides: {
    MuiTableCell: {
      root: {
        padding: '.7rem 1rem'
      }
    },
    MuiTableRow: {
      root: {
        "&:first-child td": {
          backgroundColor: palette.primary.main,
          color: palette.text.white,
          fontWeight: 700
        }
      }
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 16
        }
      }
    },
    MuiSwitch: {
      root: {
        width: 24,
        height: 24,
        padding: '.5rem .14rem'
      },
      thumb: {
        width: 8,
        height: 8,
        padding: 0
      },
      switchBase: {
        padding: 8,
        left: -5,
        '&$checked': {
          transform: 'translateX(10px)',
          color: 'white !important'
        },
        '&$checked + $track': {
          opacity: 1,
        }
      },
      track: {
        backgroundColor: palette.primary.main,
        opacity: 1,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      },
      containedSecondary: {
        color: 'white',
        '&:hover': {
          backgroundColor: palette.secondary.light
        },
        '&:disabled': {
          color: 'rgba(253, 253, 253, 0.5)',
          backgroundColor: palette.secondary.main
        }
      },
      containedSizeLarge: {
        padding: '.42rem 1.42rem',
        fontSize: 22,
      },
      outlined: {
        borderColor: palette.primary.main,
        boxShadow: '0 0 2px rgba(10, 10, 10, .14), 0 2px 2px rgba(10, 10, 10, .12), 0 0 2px rgba(10, 10, 10, .14)'
      },
      outlinedSizeLarge: {
        padding: '.42rem 1.42rem',
        fontSize: '1.5rem',
      },
      outlinedPrimary: {
        backgroundColor: palette.background.default
      },
      text: {
        color: 'white',
      },
    },
    MuiInputBase: {
      input: {
        height: '1.42rem',
        padding: '11px 16px'
      }
    },
    MuiFormControl:{
      root: {
        marginBottom: '.5rem',
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: palette.background.default,
        '& $notchedOutline': {
          top: 0,
        },
        '& legend': {
          display: 'none'
        },
      },
      input: {
        padding: '1.14rem 1.14rem .42rem'
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 14px) scale(1)',
        '&$shrink': {
          transform: 'translate(14px, 3px) scale(.75)',
        }
      },

    },
    MuiRadio: {
      root: {
        padding: 5,
        '& $label': {
          transform: 'translate(14px, 13px) scale(1)',
        },

      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1rem'
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '2.85rem 4.3rem .7rem'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '.7rem 4.3rem 2.85rem'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '1rem 4.3rem'
      }
    },
    MuiLinearProgress: {
      root: {
        height: 10,
        borderRadius: 5,
        boxShadow: '0 0 2px rgba(10, 10, 10, 0.14), 0 2px 2px rgba(10, 10, 10, 0.12), 0 0 2px rgba(10, 10, 10, 0.14)',
      },
      colorPrimary: {
        backgroundColor: palette.background.default
      }
    },
    MuiTablePagination: {
      root: {
        borderBottom: 'none'
      }
    }
  },
});

export default theme
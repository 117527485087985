import io from 'socket.io-client';
import {
  CLIENT_DISCONNECT, DOM_DID_MUTATED,
  REQUEST_CLIENT_DATA, SEND_NEW_TREE_MIRROR, SET_CLIENT_DATA,
  SOCKET_CONNECT, START,
  SUCCESS
} from "../actionTypes";

const server = 'http://localhost:3001';
const socket = io(server, {
  autoConnect: false
});

export default store => {
  const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const getWindowScroll = () => ({
    x: window.scrollX,
    y: window.scrollY
  });

  let mousePositionTimer = null;
  const getMousePosition = (e, token) => {
    if (mousePositionTimer) {
      clearTimeout(mousePositionTimer);
    }
    mousePositionTimer = setTimeout(() => {
      socket.emit('data from client', {
        dataType: 'position',
        token,
        position: {
          x: e.clientX,
          y: e.clientY
        }
      })
    }, 200)
  }

  let scrollTimer = null;
  const getScroll = (token) => {
    if (scrollTimer) {
      clearTimeout(mousePositionTimer);
    }
    scrollTimer = setTimeout(() => {
      socket.emit('data from client', {
        dataType: 'scroll',
        token,
        scroll: getWindowScroll()
      })
    }, 200)
  }

  const documentToString = (document) =>{
    let doc = new DOMParser().parseFromString( new XMLSerializer().serializeToString(document), "text/html");
    let all = doc.getElementsByTagName('script');

    for (let i = (all.length-1); i >= 0; i--) {
      all[i].parentNode.removeChild(all[i]);
    }
    return new XMLSerializer().serializeToString(doc)
  }

  const sendInitialData = (token) => {
    socket.emit('data from client', {
      dataType: 'clientDOM',
      token: token,
      clientDOM: documentToString(document)
    });
    socket.emit('data from client', {
      dataType: 'size',
      token: token,
      size: getWindowSize()
    });
    socket.emit('data from client', {
      dataType: 'scroll',
      token: token,
      size: getWindowScroll()
    });
  }


  socket.on('connect', () => {
    store.dispatch({type: SOCKET_CONNECT + SUCCESS, response: {id: socket.id}});
  });
  socket.on('disconnected', (response) => {
    store.dispatch({
      type: CLIENT_DISCONNECT,
      response: response
    })
  });

  socket.on('dom transfer', response => {
    store.dispatch({type: response.data.type, response});
  });

  socket.on('data from client', response => {
    store.dispatch({
      type: SET_CLIENT_DATA,
      response: response
    })
  });

  socket.on('request client data', (response) => {
    sendInitialData(response.token);
  });

  return next => action => {
    const {type, payload} = action;

    switch (type) {
      case SOCKET_CONNECT:
        if (!store.getState().assistant.socketId && !store.getState().assistant.connection) {
          socket.open();
          socket.emit('subscribe', payload);
          next({...action, type: type + START});
          if (store.getState().assistant.role) {
            window.addEventListener('mousemove', (e) => getMousePosition(e, payload.token));
            window.addEventListener('scroll', () => getScroll(payload.token));
          }
        }
        break;
      case REQUEST_CLIENT_DATA:
        socket.emit('request client data', payload);
        break;
      case DOM_DID_MUTATED:
        socket.emit('data from client', {
          dataType: 'clientDOM',
          token: store.getState().assistant.sessionToken,
          clientDOM: documentToString(document)
        });
        break;
      case SEND_NEW_TREE_MIRROR:
        sendInitialData(payload.token);
        break;
      default:
        return next(action);
    }
    return next(action)
  }
}

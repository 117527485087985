import React, {Suspense, useEffect, useState, Fragment} from 'react';
import classes from './App.module.sass';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import {Switch, Route, useRouteMatch} from 'react-router-dom';

import HomePage from '../../pages/home/HomePage';
import Error404 from '../../pages/error/Error404';
import Loader from '../Loader/Loader';
import Success from '../../pages/success/Success';
import {connect} from 'react-redux';
import {loadMenu, setError} from '../../store/actions';
import {isAuthenticated} from "../../store/actions/user";
import Modal from "../Modal";
import Login from "../../pages/authentication/Login";
import Register from "../../pages/authentication/register/Register";
import ForgotPassword from "../../pages/authentication/ForgotPassword";
import { loadRules } from "../../store/actions/extra";

const UserAccount = React.lazy(() => import('../../pages/account/UserAccount'));
const Document = React.lazy(() => import('../../pages/document/Document'));
const SessionPage = React.lazy(() => import('../../pages/session/SessionPage'));


function App(props) {
  const [isModalShow, setIsModalShow] = useState(false);
  const isAccountPage = useRouteMatch('/account');

  useEffect(() => {
    if (!props.menus.length) {
      props.loadMenu();
    }
    if (!props.rules) {
      props.loadRules();
    }
    if (window.localStorage.getItem('access-token')) {
      props.isAuthenticated();
    }
  }, []);
  const clearErrorMsg = () => {
    props.setError(null)
  }
  return (
    <Fragment>
      <Header isAccountPage={isAccountPage}/>
      <main className={`${classes.App} language-${props.language}`}>
        {props.loading ? <Loader/> : null}
        <Switch>
          <Route path="/login">
            <Login/>
          </Route>
          <Route path="/register">
            <Register/>
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword/>
          </Route>
          <Route
            path="/document/:token"
            render={() => (
              <Suspense fallback={<Loader/>}>
                <Document/>
              </Suspense>
            )}/>
          <Route
            path="/session/:token"
            render={() => (
              <Suspense fallback={<Loader/>}>
                <SessionPage/>
              </Suspense>
            )}/>
          <Route path="/" exact>
            <HomePage/>
          </Route>

          <Route
            path="/account"
            render={() => (
              <Suspense fallback={<Loader/>}>
                <UserAccount/>
              </Suspense>
            )}
          />

          <Route path="/success">
            <Success/>
          </Route>

          <Route component={Error404}/>
        </Switch>
      </main>
      {props.errorMsg ?
        <Modal
          type="error"
          isOpen={true}
          onClose={clearErrorMsg}
          content={props.errorMsg}/> :
        null}
      {props.rules ?
        <Modal isOpen={isModalShow} onClose={setIsModalShow} htmlContent={props.rules}/> :
        null}
      <Footer menus={props.menus} rules={props.rules} toggleModal={setIsModalShow} isAccountPage={isAccountPage}/>
    </Fragment>);
}

export default connect((store) => ({
  menus: store.app.menus,
  rules: store.app.rules,
  errorCode: store.app.errorCode,
  errorMsg: store.app.errorMsg,
  loading: store.app.loading,
  language: store.app.language
}), {loadMenu, loadRules, setError, isAuthenticated})(App);

import {
  ADD_DOCUMENT_PASSWORD,
  CLEAN_STORE,
  IS_AUTHENTICATED,
  LOAD_DOCUMENT,
  LOAD_DOCUMENT_FILE,
  PROCESS,
  SEND_FORM,
  START,
  SUCCESS,
  UPDATE_FORM_FIELD,
} from '../actionTypes';

const defaultState = {
  senderEmail: '',
  senderName: '',
  senderComment: '',
  recipientEmail: '',
  recipientName: '',
  periodOfLife: 1,
  protectByPassword: 0,
  signingType: 2,
  password: '',
  fileForSigning: null,
  uploading: false,
  uploaded: false,
  percentCompleted: 0,
  accessToken: null,
};

export default (form = defaultState, action) => {
  const {
    type,
    payload,
    response,
    percentCompleted,
  } = action;
  switch (type) {
    case UPDATE_FORM_FIELD:
      return {
        ...form,
        [payload.name]: payload.value,
      };
    case ADD_DOCUMENT_PASSWORD + SUCCESS:
      return {
        ...form,
        password: response.data.password,
      };
    case SEND_FORM + START:
      return {
        ...form,
        uploading: true,
      };
    case SEND_FORM + PROCESS:
      return {
        ...form,
        percentCompleted,
      };
    case SEND_FORM + SUCCESS:
      return {
        ...form,
        uploading: false,
        uploaded: true,
        accessToken: response.data.document.accessToken,
      };
    case LOAD_DOCUMENT + SUCCESS:
      return {
        ...form,
        senderEmail: response.data.document.sender.email,
        senderName: response.data.document.sender.name,
        recipientEmail: response.data.document.recipient.email,
        recipientName: response.data.document.recipient.name,
        signingType: response.data.document.signingType,
      };
    case LOAD_DOCUMENT_FILE + SUCCESS:
      return {
        ...form,
        fileForSigning: new Blob([response], { type: 'application/pdf' }),
      };
    case IS_AUTHENTICATED + SUCCESS:
      if (response.data.user.isAuthenticated) {
        return {
          ...form,
          senderEmail: response.data.user.email,
          senderName: `${response.data.user.firstName} ${response.data.user.lastName}`,
        };
      }
      return form;
    case CLEAN_STORE:
      return defaultState;
    default:
      return form;
  }
};

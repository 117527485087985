import React from 'react';
import classes from './Footer.module.sass';
import logo from '../../assets/img/dev-logo.svg';


const Footer = (props) => {
  return (
    <footer className={classes.Footer}>
      <div className={'container ' + (props.isAccountPage ? classes.innerContainer : '')}>
        <div className={classes.row}>
          <a href="//arixess.com" target="_blank" rel="noopener noreferrer" className={classes.logo}>
            <img src={logo} alt=""/>
          </a>
          {props.menus.length ?
          <nav>
            {props.menus.map((item) => <a key={item.name} href={item.url} target='_blank' rel="noopener noreferrer" className={classes.link}>{item.name}</a>)}
          </nav> : null}
          <button onClick={()=> props.toggleModal(true)}>Read Rules</button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import {
  CLIENT_DISCONNECT, FAIL, SET_CLIENT_DATA,
  SET_ROLE,
  SOCKET_CONNECT, START, SUCCESS,
} from '../actionTypes';
import { AssistantAction, AssistantReducer } from '../types/assistant';
import { concatActions } from '../../helpers';

const defaultState: AssistantReducer = {
  socketId: null,
  sessionToken: null,
  clientId: null,
  role: 0,
  size: {
    width: 0,
    height: 0,
  },
  position: {
    x: 0,
    y: 0,
  },
  scroll: {
    x: 0,
    y: 0,
  },
  connection: false,
  connected: false,
  clientDOM: '',
};

export default (assistant = defaultState, action: AssistantAction): AssistantReducer => {
  switch (action.type) {
    case concatActions(SOCKET_CONNECT, START):
      return {
        ...assistant,
        sessionToken: action.payload.token,
        connection: true,
      };
    case concatActions(SOCKET_CONNECT, SUCCESS):
      return {
        ...assistant,
        connection: false,
        connected: true,
        socketId: action.response.id,
      };
    case SOCKET_CONNECT + FAIL:
      return {
        ...assistant,
        connection: false,
        connected: false,
        sessionToken: null,
      };
    case SET_ROLE:
      return {
        ...assistant,
        role: action.payload.role,
      };
    case SET_CLIENT_DATA:
      return {
        ...assistant,
        [action.response.dataType]: action.response[action.response.dataType],
      };
    case CLIENT_DISCONNECT:
      return {
        ...assistant,
        size: { width: 0, height: 0 },
        position: { x: 0, y: 0 },
        scroll: { x: 0, y: 0 },
        clientDOM: '',
        clientId: null,
      };
    default:
      return assistant;
  }
};

import { combineReducers } from 'redux';
import form from './uploadForm';
import document from './document';
import app from './app';
import user from './user';
import templates from './templates';
import assistant from './assistant';
import company from './company';
import clients from './clients';

const rootReducer = combineReducers({
  form, document, app, user, templates, assistant, company, clients
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

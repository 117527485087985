import React, {Fragment} from 'react';
import {TextField} from '@material-ui/core';
import classes from './FormFields.module.sass';
import {emailValidation} from '../../../helpers';
import {useTranslation} from "react-i18next";

const FormFields = (props) => {
  const {t} = useTranslation();
  const {senderEmail, senderName, recipientEmail, recipientName, senderComment} = props.fieldsVal;


  return props.signingType === 1 ?
    <TextField
      fullWidth
      variant="outlined"
      label={t('homePage.form.emailField.placeholder')}
      name="senderEmail"
      value={senderEmail}
      error={!emailValidation(senderEmail)}
      onChange={(e) => props.changed(e)}/> :
    <Fragment>
      <div className={classes.row}>
        <div className={classes.fll}>
          {t('homePage.form.toLabel')}
        </div>
        <div className={classes.col}>
          <TextField
            fullWidth
            label={t('homePage.form.toNameField.placeholder')}
            variant="outlined"
            name="recipientName"
            value={recipientName}
            onChange={(e) => props.changed(e)}/>
        </div>
        <div className={classes.col}>
          <TextField
            fullWidth
            label={t('homePage.form.toEmailField.placeholder')}
            variant="outlined"
            name="recipientEmail"
            value={recipientEmail}
            error={!emailValidation(recipientEmail)}
            onChange={(e) => props.changed(e)}/>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.fll}>
          {t('homePage.form.fromLabel')}
        </div>
        <div className={classes.col}>
          <TextField
            fullWidth
            label={t('homePage.form.fromNameField.placeholder')}
            variant="outlined"
            name="senderName"
            value={senderName}
            onChange={(e) => props.changed(e)}/>
        </div>
        <div className={classes.col}>
          <TextField
            fullWidth
            label={t('homePage.form.fromEmailField.placeholder')}
            variant="outlined"
            name="senderEmail"
            value={senderEmail}
            error={!emailValidation(senderEmail)}
            onChange={(e) => props.changed(e)}/>
        </div>
      </div>
      {props.showCommets ?
            <div className={classes.textareaWrap}>
              <button className={classes.close} onClick={props.toggleComment}>×</button>
              <TextField
                fullWidth
                multiline
                label={t('homePage.form.commentField.placeholder')}
                variant="outlined"
                name="senderComment"
                value={senderComment}
                onChange={(e) => props.changed(e)}/>
            </div> : null}
    </Fragment>
};

export default FormFields;
import React from 'react';
import {Radio, RadioGroup, Grid} from '@material-ui/core';
import classes from './Period.module.css';
import {useTranslation} from "react-i18next";

const Period = (props) => {
  const {t} = useTranslation();
  return (
    <Grid container alignItems="center" spacing={0}>
      <Grid item className={classes.w}>
        {t('homePage.periodField.label')}
      </Grid>
      <Grid item>
        <RadioGroup
          row
          aria-label="period"
          name="periodOfLife"
          defaultValue="top"
          value={props.periodOfLife}
          onChange={(e) => props.changed(e)}>
          <label className={classes.radioWrap}>
            <Radio value={1} color="primary" size='small'/>
            <span>
              {t('homePage.periodField.firstLabel')}
            </span>
          </label>
          <label className={classes.radioWrap}>
            <Radio value={2} color="primary" size='small'/>
            <span>
              {t('homePage.periodField.secondLabel')}
            </span>
          </label>
          <label className={classes.radioWrap}>
            <Radio value={3} color="primary" size='small'/>
            <span>
              {t('homePage.periodField.thirdLabel')}
            </span>
          </label>
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default Period;
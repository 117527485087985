import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import classes from './DropZone.module.sass';
import {connect} from 'react-redux';
import {updateFormField, setError} from '../../store/actions';
import {LinearProgress} from '@material-ui/core';
import {useTranslation} from "react-i18next";


const DropZone = (props) => {
  const {t} = useTranslation();
  const setFile = (name, value) => {
    if (value.size > 8000000){
      props.setError('File is to big. Max file size 8Mb');
      props.updateFormField(name, null);
    }
    else {
      props.setError(null);
      props.updateFormField(name, value);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile('fileForSigning', acceptedFiles[0])
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div className={classes.DropZoneWrap}>
      <div className="container">
        {!props.uploaded && !props.uploading ?
          <div className={classes.dropZone} {...getRootProps()}>
            <input {...getInputProps()} accept="application/pdf"/>
            {props.fileForSigning ?
              props.fileForSigning.name :
              <div>
                <p className={classes.title}>
                  {t('homePage.dragAndDropArea.firstLabel')}
                </p>
                <p>{t('homePage.dragAndDropArea.secondLabel')}</p>
                <p>{t('homePage.dragAndDropArea.thirdLabel')}</p>
              </div>
            }
          </div> :
          <div className={classes.pgw}>
            <div className={classes.lb}>{props.percentCompleted} %</div>
            <LinearProgress variant="determinate" value={props.percentCompleted}/>
          </div>}
      </div>
    </div>
  )
}

export default connect((state) => ({
  fileForSigning: state.form.fileForSigning,
  uploaded: state.form.uploaded,
  uploading: state.form.uploading,
  percentCompleted: state.form.percentCompleted
}), {updateFormField , setError})(DropZone)
import React, {useState, useEffect, Fragment} from 'react';
import {useHistory, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classes from '../authentication.module.sass';
import registerImg from "../../../assets/img/register-img.jpg";
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import FacebookLoginButton from "../FacebookLoginButton";
import {emailValidation} from '../../../helpers';
import {registerNewUser} from '../../../store/actions/user';
import {getCountries} from "../../../store/actions/extra";
import {connect} from 'react-redux';
import useCustomForm from "../../../hooks/useCustomForm";
import AccountTypeForm from "./AccountTypeForm";
import CompanyForm from "./CompanyForm";
import PersonalForm from "./PersonalForm";
import {ArrowBackIos} from "@material-ui/icons";

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  companyName: '',
  address: '',
  postCode: '',
  city: '',
  country: '',
  vatNumber: '',
  accountType: '0',
  acceptTermsAndConditions: false
}


const Register = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);


  const {values, handleChange} = useCustomForm(initialValues);

  useEffect(() => {
    if (props.isAuth) {
      history.replace('/account')
    }
  }, [props.isAuth]);

  const handleSubmit = () => {
    props.registerNewUser(values, values.accountType === '2');
  }

  const isFormValid = () => {
    const personalDataValid = emailValidation(values.email) &&
      values.password.length > 6 &&
      values.firstName.length > 2 &&
      values.lastName.length > 2;
    const companyDataValid = values.companyName.length > 2 &&
      values.address.length > 2 &&
      values.postCode.length > 2 &&
      values.city.length > 2 &&
      values.country.length &&
      values.vatNumber.length > 2;

    if (values.accountType === '1') {
      return personalDataValid && values.acceptTermsAndConditions
    } else {
      if (activeStep === 1) {
        return personalDataValid
      }
      if (activeStep === 2) {
        return companyDataValid && values.acceptTermsAndConditions;
      }
    }
  }


  const setAccountType = (e) => {
    setActiveStep(1);
    handleChange(e);
  }


  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return <PersonalForm handleChange={handleChange} values={values}/>;
      case 2:
        return <CompanyForm handleChange={handleChange} values={values} countries={props.countries} getCountries={props.getCountries}/>;
      default:
        return <AccountTypeForm handleChange={setAccountType}/>;
    }
  }
  const prevStep = () => {
    setActiveStep(activeStep - 1);
  }

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  }





  return (
    <section className={classes.wrap}>
      <Card className={classes.cardWrap}>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item md={6} xs={12}>
              <img src={registerImg} alt=""/>
            </Grid>
            <Grid item md={6} xs={12} className={classes.formWrap}>
              <h1>{t('registerPage.mainTitle')}</h1>
              <div className={classes.form}>
                <Grid container spacing={1} justify="space-between" alignItems="flex-start">
                  <Grid item>
                    <h4>{t(`registerPage.subtitle.${activeStep}`)}</h4>
                  </Grid>
                  {activeStep > 0 ?
                    <Grid item>
                      <button
                        onClick={prevStep}
                        className={classes.backBtn}><ArrowBackIos/> Back
                      </button>
                    </Grid> : null}
                </Grid>
                {getStepContent(activeStep)}

                {activeStep === 1 && values.accountType === '2' ?
                  <Button
                    fullWidth
                    disabled={!isFormValid(activeStep, values.accountType)}
                    onClick={nextStep}
                    size="large"
                    variant="contained"
                    color="secondary">
                    Next
                  </Button> : null}
                {activeStep > 0 && (activeStep === 2 || values.accountType === '1') ?
                  <Fragment>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={values.acceptTermsAndConditions}
                          onChange={handleChange}
                          name="acceptTermsAndConditions" />
                      }
                      label="I accept the Terms and Conditions"
                    />
                    <Button
                      fullWidth
                      disabled={!isFormValid(activeStep, values.accountType)}
                      onClick={handleSubmit}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="large">{t('registerPage.form.registerButton.text')}</Button>
                  </Fragment>
                   : null }
                {activeStep > 0 && values.accountType === '1' ?
                  <Fragment>
                    <div className={classes.separator}>{t('registerPage.form.separator')}</div>
                    <FacebookLoginButton btnText={t('registerPage.form.facebookButton.text')}/>
                  </Fragment> : null }
                <div className={classes.linksWrap}>
                  <Link to="/login">{t('registerPage.form.loginButton.text')}</Link>
                  /
                  <Link to="/forgot-password">{t('registerPage.form.forgotButton.text')}</Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </section>
  );
};

export default connect((store) => ({
  isAuth: store.user.isAuth,
  countries: store.app.countries
}), {registerNewUser, getCountries})(Register);

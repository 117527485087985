import { SUCCESS, FAIL, PROCESS, API } from '../actionTypes';
import { Middleware, Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { Action } from '../types';
import { concatActions } from '../../helpers';

type ResponseToken = {
  headers: {
    'access-token': string
  }
}
type ResponseDocument = AxiosResponse & {
  data: Blob,
}

const apiNew: Middleware = (store) => (next: Dispatch) => (action: any) => {
  const { type, api } = action;
  if (!api) {
    return next(action);
  }

  const setToken = (response: ResponseToken) => {
    if (response.headers['access-token']) {
      window.localStorage.setItem('access-token', response.headers['access-token']);
    }
  };

  const uploadProgress = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    next({ ...action, type: concatActions(type, PROCESS), percentCompleted });
  };

  const downloadFile = (response: ResponseDocument) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2)
        fileName = fileNameMatch[1];
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  next(action);

  axios.request({
    method: api.method,
    url: api.url,
    data: api.data,
    params: {
      language: store.getState().app.language,
      ...api.params,
    },
    responseType: api.responseType || 'json',
    onUploadProgress: api.progress && uploadProgress,
    headers: {
      'Access-Token': window.localStorage.getItem('access-token')
    }
  })
    .then((response) => {
      if (api.downloadFile) {
        downloadFile(response);
      }
      setToken(response);
      next({ ...action, type: concatActions(type, SUCCESS), response: response.data });
    })
    .catch((error) => {
      next({ ...action, type: concatActions(type, FAIL), error });
      next({ ...action, type: API + FAIL, error: error.response });
    });
}

export default apiNew;

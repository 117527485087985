import React from 'react';
import {CircularProgress} from '@material-ui/core'
import classes from './Loader.module.sass'


const Loader = () => {
  return (
    <div className={classes.w}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
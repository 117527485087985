import React, {Fragment} from 'react';
import DropZone from '../../components/DropZone/DropZone';
import Form from '../../components/UploadForm/Form';

const HomePage = () => {
  return (
    <Fragment>
      <DropZone/>
      <Form/>
    </Fragment>
  );
};

export default HomePage;
import React, {Suspense} from 'react'
import App from './App/App'
import store from '../store'
import {Provider} from 'react-redux'
import theme from '../UI/theme';
import {MuiThemeProvider} from '@material-ui/core';
import Backend from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import {DndProvider} from 'react-dnd';
import {BrowserRouter as Router} from 'react-router-dom';
import Loader from "./Loader/Loader";
import '../i18n';

function Root() {
  const isTouchDevice = () => {
    return !!('ontouchstart' in window
      || navigator.maxTouchPoints);
  }
  return (
    <Provider store = {store}>
      <Router>
        <DndProvider backend={isTouchDevice() ? TouchBackend : Backend}>
          <MuiThemeProvider theme={theme}>
            <Suspense fallback={<Loader/>}>
              <App/>
            </Suspense>
          </MuiThemeProvider>
        </DndProvider>
      </Router>
    </Provider>
  )
}

export default Root

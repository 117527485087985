export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const ADD_DOCUMENT_PASSWORD = 'ADD_DOCUMENT_PASSWORD';
export const ADD_DOCUMENT_PAGE = 'ADD_DOCUMENT_PAGE';
export const SEND_FORM = 'SEND_FORM';

export const ADD_DOCUMENT_FIELD = 'ADD_DOCUMENT_FIELD';
export const UPDATE_DOCUMENT_FIELD = 'UPDATE_DOCUMENT_FIELD';
export const REMOVE_DOCUMENT_FIELD = 'REMOVE_DOCUMENT_FIELD';
export const ADD_DOCUMENT_FIELD_OPTION = 'ADD_DOCUMENT_FIELD_OPTION';
export const REMOVE_DOCUMENT_FIELD_OPTION = 'REMOVE_DOCUMENT_FIELD_OPTION';
export const SEND_DOCUMENT_FIELDS = 'SEND_DOCUMENT_FIELDS';
export const LOAD_DOCUMENT_FILE = 'LOAD_DOCUMENT_FILE';
export const SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PAGE_SIZES = 'SET_PAGE_SIZES';
export const COMPOSE_DOCUMENT = 'COMPOSE_DOCUMENT';
export const CLEAR_DOCUMENT_STATE = 'CLEAR_DOCUMENT_STATE';
export const SWITCH_AUDIT_TRAIL = 'SWITCH_AUDIT_TRAIL';

export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';

export const GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION';
export const UPDATE_COMPANY_INFORMATION = 'UPDATE_COMPANY_INFORMATION';
export const GET_COMPANY_USER_ROLES = 'GET_COMPANY_USER_ROLES';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER';
export const ADD_COMPANY_USER = 'ADD_COMPANY_USER';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const GET_DOCUMENT_SENDERS = 'GET_DOCUMENT_SENDERS';

export const ADD_TEMPLATE_FILE = 'ADD_TEMPLATE_FILE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const LOAD_TEMPLATE_FILE = 'LOAD_TEMPLATE_FILE';
export const LOAD_TEMPLATE_FIELDS = 'LOAD_TEMPLATE_FIELDS';

export const SET_TEMPLATES_FOR_SENDING = 'SET_TEMPLATES_FOR_SENDING';
export const SET_TEMPLATE_RECIPIENT = 'SET_TEMPLATE_RECIPIENT';
export const SEND_TEMPLATE = 'SEND_TEMPLATE';

export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

export const CLEAN_STORE = 'CLEAN_STORE';

export const SET_ERROR = 'SET_ERROR';

export const API = 'API';

export const SET_DOCUMENT_OWNER = 'SET_DOCUMENT_OWNER';
export const IS_DOCUMENT_PROTECTED = 'IS_DOCUMENT_PROTECTED';
export const LOAD_DOCUMENT = 'LOAD_DOCUMENT';

export const LOAD_MENUS = 'LOAD_MENUS';
export const LOAD_RULES = 'LOAD_RULES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_COUNTRIES = 'GET_COUNTRIES';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const CLIENT_DISCONNECT = 'CLIENT_DISCONNECT';
export const SET_ROLE = 'SET_ROLE';
export const REQUEST_CLIENT_DATA = 'REQUEST_CLIENT_DATA';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';

export const SEND_NEW_TREE_MIRROR = 'SEND_NEW_TREE_MIRROR';
export const WATCH_FOR_DOM = 'WATCH_FOR_DOM';
export const DOM_DID_MUTATED = 'DOM_DID_MUTATED';

export const GET_CLIENTS = 'GET_CLIENTS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_CLIENT_SESSIONS = 'GET_CLIENT_SESSIONS';
export const SEND_TEMPLATE_TO_CLIENT = 'SEND_TEMPLATE_TO_CLIENT';

export const START = '_START';
export const PROCESS = '_PROCESS';
export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';

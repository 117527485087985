import React from 'react';
import classes from './Success.module.sass';
import imgSuccess from '../../assets/img/success.png';
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Success = () => {
  const {t} = useTranslation();
  const {token} = useParams();
  return (
    <div className={classes.sc}>
      <div className="container">
        <h2>
          {t('successPage.firstText')}
        </h2>
        <img src={imgSuccess} alt=""/>
        {token ?
          <div className={classes.linkWrap}>
            {t('successPage.secondText')}:
            <a href={`${window.location.origin}/document/${token}/filling`} target="_blank"> {window.location.origin}/document/{token}/filling</a>
          </div> : null}
        <Link to="/">{t('successPage.thirdText')}</Link>
      </div>
    </div>
  );
};

export default Success;
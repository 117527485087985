import {
  API,
  FACEBOOK_LOGIN, FAIL,
  IS_AUTHENTICATED,
  REGISTER_NEW_USER, START,
  SUCCESS, UPDATE_USER,
  USER_LOGIN,
  USER_LOGOUT,
} from '../actionTypes';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  role: 0,
  isAuth: false,
  loading: false,
  loaded: false,
};

export default (user = initialState, action) => {
  const { type, response, error } = action;
  switch (type) {
    case REGISTER_NEW_USER + SUCCESS:
      return {
        ...user,
        ...response.data.user,
        isAuth: true,
      };
    case FACEBOOK_LOGIN + SUCCESS:
    case USER_LOGIN + SUCCESS:
      return {
        ...user,
        ...response.data.user,
        isAuth: true,
      };
    case USER_LOGOUT + SUCCESS:
      return initialState;
    case UPDATE_USER + START:
    case IS_AUTHENTICATED + START:
      return {
        ...user,
        loading: true,
      };
    case UPDATE_USER + SUCCESS:
    case UPDATE_USER + FAIL:
    case IS_AUTHENTICATED + FAIL:
    case FACEBOOK_LOGIN + FAIL:
      return {
        ...user,
        loading: false,
      };
    case IS_AUTHENTICATED + SUCCESS:
      return {
        ...user,
        firstName: response.data.user.firstName,
        lastName: response.data.user.lastName,
        email: response.data.user.email,
        role: response.data.user.role,
        isAuth: response.data.user.isAuthenticated,
        loading: false,
      };
    case API + FAIL:
      if (error.status === 401) {
        return initialState;
      }
      return user;
    default:
      return user;
  }
};

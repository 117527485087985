import {
  ADD_DOCUMENT_FIELD,
  ADD_DOCUMENT_FIELD_OPTION,
  ADD_DOCUMENT_PAGE,
  ADD_DOCUMENT_PASSWORD,
  API,
  CLEAR_DOCUMENT_STATE,
  COMPOSE_DOCUMENT,
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  FAIL,
  IS_DOCUMENT_PROTECTED,
  LOAD_DOCUMENT,
  LOAD_DOCUMENT_FILE,
  REMOVE_DOCUMENT_FIELD,
  REMOVE_DOCUMENT_FIELD_OPTION,
  SEND_DOCUMENT_FIELDS,
  SET_ACTIVE_FIELD,
  SET_CURRENT_PAGE,
  SET_DOCUMENT_OWNER,
  SET_PAGE_SIZES,
  SWITCH_AUDIT_TRAIL,
  UPDATE_DOCUMENT_FIELD
} from '../actionTypes';
import axios from 'axios';

export const addDocumentPage = (payload) => ({
  type: ADD_DOCUMENT_PAGE,
  payload: { ...payload }
});

export const addDocumentField = (payload) => ({
  type: ADD_DOCUMENT_FIELD,
  generateId: true,
  payload: { ...payload }
});

export const updateDocumentField = (id, name, value, setTime = false) => {
  if (setTime) {
    return dispatch => {
      axios.get('/api/v1/tools/utc-time')
        .then(response => {
          dispatch({
            type: UPDATE_DOCUMENT_FIELD,
            payload: { id, name: 'fillingTime', value: response.data.data.time }
          });
          dispatch({
            type: UPDATE_DOCUMENT_FIELD,
            payload: { id, name, value }
          });
        })
        .catch(error => {
          dispatch({
            type: API + FAIL,
            error
          });
        });
    };
  } else {
    return ({
      type: UPDATE_DOCUMENT_FIELD,
      payload: { id, name, value }
    });
  }
};
export const removeDocumentField = (id) => ({
  type: REMOVE_DOCUMENT_FIELD,
  payload: { id }
});
export const addDocumentFieldOption = (id, value) => ({
  type: ADD_DOCUMENT_FIELD_OPTION,
  generateId: true,
  payload: { id, value }
});
export const removeDocumentFieldOption = (controlId, optionId) => ({
  type: REMOVE_DOCUMENT_FIELD_OPTION,
  payload: { id: controlId, optionId },
});
export const switchAuditTrail = (value) => ({
  type: SWITCH_AUDIT_TRAIL,
  payload: value
});
export const setActiveFiled = (id) => ({
  type: SET_ACTIVE_FIELD,
  payload: { id }
});
export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: { page }
});
export const setPageSizes = (sizes) => ({
  type: SET_PAGE_SIZES,
  payload: sizes
});
export const setDocumentOwner = () => ({
  type: SET_DOCUMENT_OWNER
});
export const sendDocumentFields = (token, payload) => ({
  callAPI: `/api/v1/documents/${token}`,
  method: 'PATCH',
  type: SEND_DOCUMENT_FIELDS,
  payload
});
export const isDocumentProtected = (token) => ({
  type: IS_DOCUMENT_PROTECTED,
  callAPI: `/api/v1/documents/${token}/is-protected`,
  payload: token
});
export const loadDocument = (token, password = null) => ({
  type: LOAD_DOCUMENT,
  callAPI: `/api/v1/documents/${token}`,
  method: 'POST',
  payload: { password }
});
export const loadDocumentFile = (token, password = null) => ({
  type: LOAD_DOCUMENT_FILE,
  callAPI: `/api/v1/documents/${token}/file`,
  method: 'POST',
  payload: { password },
  responseType: 'blob',
});
export const composeDocument = (token, payload) => ({
  type: COMPOSE_DOCUMENT,
  callAPI: `/api/v1/documents/${token}/compose`,
  method: 'POST',
  payload,
});
export const downloadDocument = (token, password = null) => ({
  type: DOWNLOAD_DOCUMENT,
  callAPI: `/api/v1/documents/${token}/download`,
  method: 'POST',
  payload: { password },
  responseType: 'blob',
  downloadFile: true,
});
export const deleteDocument = (token, password = null) => ({
  type: DELETE_DOCUMENT,
  callAPI: `/api/v1/documents/${token}`,
  method: 'DELETE',
  payload: { password },
});
export const clearDocumentState = () => ({
  type: CLEAR_DOCUMENT_STATE,
});
export const getPassword = () => ({
  type: ADD_DOCUMENT_PASSWORD,
  callAPI: '/api/v1/documents/password',
});

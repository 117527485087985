export function emailValidation(val: string) {
  return val.length > 0
    ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)
    : true;
}

export const px2mm = (px: number, dpi = 72) => ((px * 25.4) / dpi);


export const mm2px = (mm: number, dpi = 72) => ((mm / 25.4) * dpi);

export const findPageByField = (pages: {fields: {[k: string]: any}}[], fieldId: string) => {
  return pages.findIndex((page) => Boolean(page.fields[fieldId]));
};

export const toBase64 = (file: File) => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  })
);

export const flatten = (arr: any[][]) => arr.reduce((flat, next) => flat.concat(next), []);

export const getScreenWidth = () => window.innerWidth;

export const normalizePagesForSending = (pages: any[]) => (
  pages.map((page) => {
    const newPage = { ...page };
    newPage.fields = [];
    newPage.fields = Object.values(page.fields);
    return newPage;
  })
);

export const formatDate = (date: Date) => {
  const newDate = new Date(date);
  const dd = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
  const mm = newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1;
  const yyyy = newDate.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
};

export const replaceNullValue = (obj: {[k: string]: any}, replaceBy = '') => {
  let newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === null) {
      newObj = {
        ...newObj,
        [key]: replaceBy,
      };
    }
  });
  return newObj;
};

type Action = {
  index: number,
  item?: any,
}
export const insertToArray = (array: any[], action: Action) => ([
  ...array.slice(0, action.index),
  action.item,
  ...array.slice(action.index),
]);

export const removeFromArray = (array: any[], action: Action) => ([
  ...array.slice(0, action.index),
  ...array.slice(action.index + 1),
]);

export const updateObjectInArray = (array: any[], action: Action) => (
  array.map((item, index) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      ...action.item,
    };
  })
);

export const removePropFromObj = (obj: {[k: string]: any}, prop: string) => {
  const { [prop]: omit, ...rest } = obj;
  return rest;
};

export const concatActions = <S1 extends string, S2 extends string>(s1:S1, s2: S2) => (
  s1 + s2 as `${S1}${S2}`
);
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const CompanyForm = props => {
  const {values, handleChange} = props;

  useEffect(()=> {
    if (!props.countries.length){
      props.getCountries();
    }
  }, [])

  return (
    <div>
      <TextField
        fullWidth
        type="text"
        variant="outlined"
        label={'Company name'}
        value={values.companyName}
        onChange={handleChange}
        name="companyName"/>
      <TextField
        fullWidth
        type="text"
        variant="outlined"
        label={'Address'}
        value={values.address}
        onChange={handleChange}
        name="address"/>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label={'Post Code'}
            value={values.postCode}
            onChange={handleChange}
            name="postCode"/>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label={'City'}
            value={values.city}
            onChange={handleChange}
            name="city"/>
        </Grid>
        <Grid item md={6}>
          <FormControl
            variant="outlined"
            fullWidth>
            <InputLabel>
              Country
            </InputLabel>
            <Select
              value={values.country}
              onChange={handleChange}
              name="country">
              {props.countries ? props.countries.sort((a, b) => (a.name < b.name ? -1 : 1)).map((country)=> (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              )) : null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label={'VAT Number'}
            value={values.vatNumber}
            onChange={handleChange}
            name="vatNumber"/>
        </Grid>
      </Grid>
    </div>
  );
};

CompanyForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  countries: PropTypes.oneOfType([null, PropTypes.array])
};

export default CompanyForm;

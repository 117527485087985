import {
  FAIL,
  LOAD_DOCUMENT,
  LOAD_MENUS,
  SET_ACTIVE_FIELD,
  SET_CURRENT_PAGE,
  START,
  SUCCESS,
  SET_PAGE_SIZES,
  SET_DOCUMENT_OWNER,
  SEND_FORM,
  CLEAN_STORE,
  LOAD_RULES,
  ADD_DOCUMENT_FIELD,
  IS_DOCUMENT_PROTECTED,
  SET_LANGUAGE,
  SET_ERROR,
  REGISTER_NEW_USER,
  USER_LOGIN,
  FACEBOOK_LOGIN,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
  API, GET_COUNTRIES,
} from '../actionTypes';

type MenuItem = {
  name: string,
  url: string,
}

type Country = {
  id: string,
  name: string,
}

type AppReducer = {
  errorCode: number | null,
  errorMsg: string | null,
  isPasswordValid: boolean,
  activeField: string | null,
  currentPage: number | null,
  pageScaleFactor: number | null,
  pageWidth: number | null,
  pageHeight: number | null,
  isOwner: boolean,
  menus: MenuItem[],
  rules: string | null,
  countries: Country[],
  language: string,
  loading: boolean,
}

const defaultState: AppReducer = {
  errorCode: null,
  errorMsg: null,
  isPasswordValid: true,
  activeField: null,
  currentPage: 1,
  pageScaleFactor: 1,
  pageWidth: null,
  pageHeight: null,
  isOwner: false,
  menus: [],
  rules: null,
  countries: [],
  language: 'en',
  loading: false,
};

export default (app = defaultState, action: any): AppReducer => {
  const {
    type,
    payload,
    randomId,
    response,
    error,
  } = action;
  switch (type) {
    case SET_ACTIVE_FIELD:
      return {
        ...app,
        activeField: payload.id,
      };
    case ADD_DOCUMENT_FIELD:
      return {
        ...app,
        activeField: randomId,
      };
    case SET_CURRENT_PAGE:
      return {
        ...app,
        currentPage: payload.page,
      };
    case LOAD_DOCUMENT + START:
      return {
        ...app,
        isPasswordValid: true,
        errorMsg: null,
      };
    case LOAD_DOCUMENT + FAIL:
      return {
        ...app,
        isPasswordValid: false,
        errorMsg: error.response.data.message,
      };
    case LOAD_MENUS + SUCCESS:
      return {
        ...app,
        menus: response.data.menus,
      };
    case LOAD_RULES + SUCCESS:
      return {
        ...app,
        rules: response.data.rules,
      };
    case SET_LANGUAGE:
      return {
        ...app,
        language: payload.language,
      };
    case SET_PAGE_SIZES:
      return {
        ...app,
        pageScaleFactor: payload.scaleFactor,
        pageWidth: payload.width,
        pageHeight: payload.height,
      };
    case SEND_FORM + SUCCESS:
    case SET_DOCUMENT_OWNER:
      return {
        ...app,
        isOwner: true,
      };
    case IS_DOCUMENT_PROTECTED + FAIL:
      return {
        ...app,
        loading: false,
        errorMsg: error.response.data.message,
        errorCode: error.response.status,
      };
    case USER_LOGIN + START:
    case FACEBOOK_LOGIN + START:
    case DELETE_TEMPLATE + START:
    case REGISTER_NEW_USER + START:
    case IS_DOCUMENT_PROTECTED + START:
      return {
        ...app,
        loading: true,
      };
    case USER_LOGIN + SUCCESS:
    case FACEBOOK_LOGIN + SUCCESS:
    case DELETE_TEMPLATE + SUCCESS:
    case REGISTER_NEW_USER + SUCCESS:
    case IS_DOCUMENT_PROTECTED + SUCCESS:
      return {
        ...app,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...app,
        errorMsg: payload,
      };
    case REGISTER_NEW_USER + FAIL:
      return {
        ...app,
        loading: false,
        errorMsg: Object.values(error.response.data.data).join('/n'),
      };
    case USER_LOGIN + FAIL:
      return {
        ...app,
        loading: false,
        errorMsg: error.response.data.message,
      };
    case API + FAIL:
      return {
        ...app,
        errorMsg: error.response.data.message,
      };
    case UPDATE_TEMPLATE + SUCCESS:
      return {
        ...app,
        activeField: null,
        currentPage: null,
        pageScaleFactor: null,
        pageWidth: null,
        pageHeight: null,
      };
    case GET_COUNTRIES + SUCCESS:
      return {
        ...app,
        countries: response.data.countries.sort((a: any, b: any) => (a.name < b.name ? -1 : 1)),
      };
    case CLEAN_STORE:
      return {
        ...defaultState,
        menus: app.menus,
        rules: app.rules,
      };
    default:
      return app;
  }
};

import React from 'react';
import { Dialog, DialogContent, IconButton, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classes from './Modal.module.sass';

type Props = {
  isOpen: boolean,
  onClose: (isOpen: boolean) => void,
  content?: any,
  type?: string,
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  className?: string,
  modalTitle?: string,
  fullWidth?: boolean,
  htmlContent?: string,
}

const Modal: React.FC<Props> = (props) => {
  return (
    <Dialog
      fullWidth={props.fullWidth}
      className={classes.modal + ' ' + props.className}
      open={props.isOpen}
      maxWidth={props.maxWidth}
      onClose={() => props.onClose(false)}>
      {props.modalTitle ? <DialogTitle>{props.modalTitle}</DialogTitle> : null}
      {props.htmlContent ? <DialogContent dangerouslySetInnerHTML={{__html: props.htmlContent}}/> : null}
      <DialogContent className={props.type ? classes[props.type] : ''}>
        {props.content}
        {props.children}
      </DialogContent>
      <IconButton size="small" className={classes.close} onClick={() => props.onClose(false)}>
        <Close/>
      </IconButton>
    </Dialog>
  );
};

export default Modal;
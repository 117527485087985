import React, {useState, useEffect} from 'react';
import {Switch, Grid, Button} from '@material-ui/core';
import Period from './Period/Period';
import Password from './Password/Password';
import FormFields from './FormFields/FormFields';
import {emailValidation} from '../../helpers';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';

import {connect} from 'react-redux';
import {updateFormField, sendForm} from '../../store/actions';

import classes from './Form.module.css';

import peopleImg from '../../assets/img/people.svg';
import manImg from '../../assets/img/man.svg';
import commentImg from '../../assets/img/message.svg';
import { getPassword } from '../../store/actions/document';


const Form = (props) => {
  const {t} = useTranslation();
  const {senderEmail, senderName, recipientEmail, recipientName, senderComment, periodOfLife, protectByPassword, signingType, fileForSigning, password, getPassword, sendForm} = props;
  const [showComments, setShowComments] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const toggleComment = () => setShowComments(!showComments);
  const history = useHistory();

  const updateField = (e, newPassword = false) => {
    const {name, value, checked, type} = e.target
    const isInt = name === 'periodOfLife'
    if (type === 'checkbox') {
      props.updateFormField(name, name !== 'signingType' ? checked * 1 : checked ? 1 : 2);
    } else {
      props.updateFormField(name, isInt ? value * 1 : value);
    }
    if (newPassword) {
      getPassword();
    }
  }

  const sendFormData = () => {
    let formData = new FormData();
    Object.keys(props).forEach(item => {
      if (item !== 'sendForm' && item !== 'getPassword' && item !== 'updateFormField') {
        formData.append(item, props[item])
      }
    });
    sendForm(formData);
  }


  useEffect(() => {
    if (
      ((emailValidation(senderEmail) && signingType === 1 && senderEmail.length) ||
        (emailValidation(senderEmail) && emailValidation(recipientEmail) && signingType === 2 && senderEmail.length && recipientEmail.length)) && fileForSigning
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false)
    }
  }, [senderEmail, recipientEmail, signingType, fileForSigning])

  useEffect(() => {
    if (props.uploaded) {
      history.push(`/document/${props.accessToken}/edit`)
    }
  }, [props.uploaded, history, props.accessToken])


  return (
    <div className="container">
      <div className={classes.form}>
        <Grid container alignItems="center" spacing={1} justify="space-between">
          <Grid item>
            <Period periodOfLife={periodOfLife} changed={updateField}/>
          </Grid>
          <Grid item>
            <Password password={password} protectByPassword={protectByPassword} changed={updateField}/>
          </Grid>
        </Grid>
        <div className={classes.target}>
          {t('homePage.typeField.firstLabel')}
          <label className={classes.sw}>
            <img src={peopleImg} alt="" style={{opacity: signingType === 1 ? .5 : 1}}/>
            <Switch name="signingType" checked={signingType === 1} onChange={(e) => updateField(e)} color="primary"/>
            <img src={manImg} alt="" style={{opacity: signingType !== 1 ? .5 : 1}}/>
          </label>
          {t('homePage.typeField.secondLabel')}
        </div>
        <div className={classes.formWrap}>
          <FormFields
            changed={updateField}
            fieldsVal={{senderEmail, senderName, recipientEmail, recipientName, senderComment}}
            signingType={signingType}
            showCommets={showComments}
            toggleComment={toggleComment}/>
          <div className={classes.btw}>
            {!showComments && signingType === 2 ?
              <Button
                className={classes.bs}
                color="primary"
                variant="contained"
                size="large"
                onClick={toggleComment}>
                <img src={commentImg} alt=""/>
              </Button> : null}

            <Button
              fullWidth
              disabled={!isFormValid || props.uploading}
              onClick={sendFormData}
              color="secondary"
              variant="contained"
              size="large">
              {t('homePage.form.submitButton.text')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({...state.form}), {updateFormField, getPassword, sendForm})(Form);